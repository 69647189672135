<template>
  <div class="pdf-parser-container">
    <b-card no-body
            class="shadow-sm"
    >
      <b-card-header class="bg-primary">
        <h2 class="mb-0 text-center  text-white">
          PDF Parser
        </h2>
      </b-card-header>

      <b-card-body class="py-4">
        <b-row>
          <!-- Left Column: Upload Controls -->
          <b-col md="4"
                 lg="3"
                 class="border-right"
          >
            <h4 class="mb-4">
              Upload & Process
            </h4>

            <!-- PDF Upload Section -->
            <b-form-group label="PDF Document"
                          label-for="pdf-upload"
                          class="mb-4"
            >
              <b-form-file
                id="pdf-upload"
                v-model="pdfFile"
                :state="Boolean(pdfFile)"
                placeholder="Choose a PDF file or drop it here..."
                drop-placeholder="Drop PDF file here..."
                accept=".pdf"
                @input="handleFileUpload"
              />
            </b-form-group>

            <b-button
              block
              variant="primary"
              :disabled="!pdfFile"
              :class="{'disabled': isUploading}"
              @click="uploadPdf"
            >
              <b-spinner v-if="isUploading"
                         small
                         class="mr-2"
              />
              {{ isUploading ? 'Processing...' : 'Parse PDF' }}
            </b-button>

            <b-form-group label="Delimiter"
                          label-for="delimiter"
                          class="my-4"
            >
              <b-form-input
                id="delimiter"
                v-model="delimiter"
                placeholder="Enter a delimiter"
              />
            </b-form-group>

            <b-button
              block
              variant="success"
              :disabled="!delimiter || isSubmitting || !markdownResponse"
              @click="processMarkDownContent"
            >
              <b-spinner v-if="isSubmitting"
                         small
                         class="mr-2"
              />
              {{ isSubmitting ? 'Submitting...' : 'Make Domain Standard' }}
            </b-button>

            <!-- Status Alerts -->
            <b-alert
              v-model="showAlert"
              :variant="alertVariant"
              dismissible
              class="mt-4 p-3"
            >
              {{ alertMessage }}
            </b-alert>

            <SavedFilesList
              :key="savedListKey"
              @file-opened="handleFileOpen"
            />
          </b-col>

          <!-- Right Column: Output View -->
          <b-col md="8"
                 lg="9"
          >
            <div v-if="markdownResponse"
                 class="markdown-editor-container"
            >
              <div class="d-flex justify-content-end align-items-center mb-4">
                <b-button-group>
                  <b-button
                    :variant="activeTab === 'edit' ? 'primary' : 'outline-primary'"
                    :pressed="activeTab === 'edit'"
                    @click="activeTab = 'edit'"
                  >
                    Edit
                  </b-button>
                  <b-button
                    :variant="activeTab === 'preview' ? 'primary' : 'outline-primary'"
                    :pressed="activeTab === 'preview'"
                    @click="activeTab = 'preview'"
                  >
                    Preview
                  </b-button>
                  <b-button
                    v-if="standardDomain"
                    variant="success"
                    :disabled="isSaving"
                    @click="saveChanges"
                  >
                    <b-spinner v-if="isSaving"
                               small
                               class="mr-1"
                    />
                    {{ isSaving ? 'Saving...' : 'Save' }}
                  </b-button>
                </b-button-group>
              </div>
              <app-collapse>
                <app-collapse-item title="Parsed Content"
                                   :is-visible="true"
                >
                  <div class="content-area">
                    <div v-if="activeTab === 'edit'"
                         class="markdown-edit-area"
                    >
                      <b-form-textarea
                        v-model="markdownResponse"
                        rows="15"
                        max-rows="25"
                        placeholder="Markdown content will appear here..."
                      />
                    </div>

                    <div
                      v-else
                      class="markdown-preview-area p-2 border rounded"
                    >
                      <VueMarkdown :key="markDownKey">
                        {{ renderedMarkdown }}
                      </VueMarkdown>
                    </div>
                  </div>
                </app-collapse-item>
              </app-collapse>
            </div>

            <!-- JSON Editor Section -->
            <div v-if="standardDomain"
                 class="mt-5"
            >
              <h4 class="mb-4">
                Domain Standard Output
              </h4>
              <div class="json-editor-wrapper border rounded">
                <JsonEditorVue
                  v-model="standardDomain"
                  :stringified="false"
                  mode="tree"
                />
              </div>
            </div>

            <!-- Empty state when no content -->
            <div v-if="!markdownResponse"
                 class="empty-state text-center py-5"
            >
              <b-icon icon="file-earmark-pdf"
                      font-scale="4"
                      class="text-muted mb-4"
              />
              <p class="text-muted h5">
                Upload a PDF file to begin parsing
              </p>
            </div>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BFormFile,
  BButton,
  BSpinner,
  BAlert,
  BButtonGroup,
  BFormTextarea,
  BFormGroup,
  BFormInput,
  BIcon,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import { parsePdfFile, makeDomainStandard } from '@/auth/jwt/useDatablock'
import JsonEditorVue from 'json-editor-vue'
import VueMarkdown from 'vue-markdown-v2'
import useJwt from '@/auth/jwt/useJwt'
import SavedFilesList from './components/SavedFileLists.vue'

export default {
  name: 'PdfParser',
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormFile,
    BButton,
    BSpinner,
    BAlert,
    BButtonGroup,
    BFormTextarea,
    BFormGroup,
    BFormInput,
    BIcon,
    SavedFilesList,
    AppCollapse,
    AppCollapseItem,
    VueMarkdown,
    JsonEditorVue,
  },
  data() {
    return {
      pdfFile: null,
      pdfBase64: '',
      markdownResponse: '',
      isUploading: false,
      showAlert: false,
      alertMessage: '',
      alertVariant: 'info',
      activeTab: 'preview',
      delimiter: '---',
      isSubmitting: false,
      isSaving: false,
      markDownKey: 0,
      savedListKey: 0,
      responses: [],
      activeFileId: null,
      standardDomain: null,
    }
  },
  computed: {
    renderedMarkdown() {
      return this.markdownResponse
    },
  },
  methods: {
    parseStandardDomain(responses) {
      try {
        const combinedDomains = responses
          .map(response => JSON.parse(response))
          .reduce((acc, curr) => acc.concat(curr.domains || []), [])

        return { domains: combinedDomains }
      } catch (error) {
        console.error('Error parsing responses or combining domains:', error)
        this.showErrorMessage(error)
        return { domains: [] }
      }
    },
    // All methods remain the same as in the previous implementation
    handleFileUpload() {
      this.showAlert = false
      this.markdownResponse = ''

      if (this.pdfFile) {
        this.convertToBase64()
      }
    },

    convertToBase64() {
      const reader = new FileReader()
      reader.readAsDataURL(this.pdfFile)
      reader.onload = () => {
        this.pdfBase64 = reader.result
      }
      reader.onerror = error => {
        console.error('Error converting file to base64:', error)
        this.showAlert = true
        this.alertVariant = 'danger'
        this.alertMessage = 'Error processing the PDF file.'
      }
    },

    uploadPdf() {
      if (!this.pdfFile) {
        this.showAlert = true
        this.alertVariant = 'warning'
        this.alertMessage = 'Please select a PDF file first.'
        return
      }
      this.activeFileId = null
      this.standardDomain = null
      this.isUploading = true

      const reader = new FileReader()
      reader.readAsArrayBuffer(this.pdfFile)
      reader.onload = () => {
        const arrayBuffer = reader.result
        let binary = ''
        const bytes = new Uint8Array(arrayBuffer)
        const len = bytes.byteLength

        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < len; i++) {
          binary += String.fromCharCode(bytes[i])
        }

        const base64String = window.btoa(binary)
        this.pdfBase64 = `data:@file/pdf;base64,${base64String}`

        this.processPdf()
      }

      reader.onerror = error => {
        console.error('Error converting file to base64:', error)
        this.isUploading = false
        this.showAlert = true
        this.alertVariant = 'danger'
        this.alertMessage = 'Error processing the PDF file.'
      }
    },

    processPdf() {
      parsePdfFile({
        message: this.pdfBase64,
      })
        .then(response => {
          const { text, files } = response.data.message.data
          console.log(files)
          this.markdownResponse = text.join(' \n')
          this.showAlert = true
          this.alertVariant = 'success'
          this.alertMessage = 'PDF parsed successfully!'
        })
        .catch(error => {
          console.error('Error parsing PDF:', error)

          let errorMessage = 'An error occurred while parsing the PDF.'

          if (error.response) {
            errorMessage = `Error ${error.response.status}: ${error.response.data.message || 'Failed to parse PDF'}`
          } else if (error.request) {
            errorMessage = 'No response from the server. Please try again.'
          } else {
            errorMessage = error.message
          }

          this.showAlert = true
          this.alertVariant = 'danger'
          this.alertMessage = errorMessage
        })
        .finally(() => {
          this.isUploading = false
        })
    },

    saveChanges() {
      const name = `${(this.pdfFile?.name || 'sample').replace(/\.[^/.]+$/, '')}.md`
      this.isSaving = true
      useJwt.createPdfParser({
        name,
        markdown: this.markdownResponse,
        standard: this.standardDomain,
        id: this.activeFileId,
      }).then(response => {
        this.savedListKey += 1
        this.showAlert = true
        this.alertVariant = 'success'
        this.alertMessage = 'Changes saved successfully!'
        this.showSuccessMessage(response)
      }).catch(() => {
        this.showAlert = true
        this.alertVariant = 'danger'
        this.alertMessage = 'Error saving pdf!'
      }).finally(() => {
        this.isSaving = false
      })
    },

    processChunk(chunk) {
      if (!this.delimiter) {
        return alert('Please enter a delimiter.')
      }

      this.isSubmitting = true

      return makeDomainStandard({ message: chunk })
        .then(res => {
          if (!res.data) {
            throw new Error(`HTTP error! status: ${res.status}`)
          }

          return res.data.message
        })
        .catch(error => {
          console.error('Error:', error)
        })
        .finally(() => {
          this.isSubmitting = false
        })
    },

    async processMarkDownContent() {
      this.errorMessage = null

      const chunks = this.markdownResponse
        .split(this.delimiter)
        .map(chunk => chunk.trim())
        .filter(chunk => chunk.length > 0)
      console.table(chunks)
      try {
        const chunkResponses = []
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < chunks.length; i++) {
          // Add delay after every 5 chunks
          if (i > 0 && i % 5 === 0) {
            // eslint-disable-next-line no-await-in-loop
            await new Promise(resolve => setTimeout(resolve, 3000))
          }
          // eslint-disable-next-line no-await-in-loop
          const response = await this.processChunk(chunks[i])
          chunkResponses.push(response)
        }

        const validResponses = chunkResponses.filter(response => response !== null && response !== undefined)
        if (chunkResponses.length !== validResponses.length) {
          console.warn('Some chunks were not processed correctly:', chunkResponses)
        }
        this.standardDomain = this.parseStandardDomain([...validResponses])
      } catch (error) {
        console.error('Error while processing markdown content:', error)
        this.errorMessage = error.message
      }
    },
    handleFileOpen({ content, standard, id }) {
      this.markdownResponse = content
      this.standardDomain = standard
      this.activeFileId = id
      this.markDownKey += 1
    },
  },
}
</script>

<style scoped>
.pdf-parser-container {
  width: 100%;
  margin: 0;
  padding: 0;
}

.border-right {
  border-right: 1px solid rgba(0,0,0,0.1);
}

.markdown-editor-container {
  margin-top: 0;
}

.content-area {
  overflow-y: auto;
}

.markdown-edit-area {
  height: 100%;
}

.markdown-preview-area {
  height: 100%;
  overflow-y: auto;
}

.json-editor-wrapper {
  overflow-y: auto;
}

.empty-state {
  height: 450px; /* Increased height */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
}

/* Add some animation for better UX */
.btn-primary, .btn-success {
  transition: all 0.2s ease;
  font-size: 1rem; /* Larger font size for buttons */
}

.btn-primary:hover, .btn-success:hover {
  transform: translateY(-1px);
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

/* Improve card appearance */
.card {
  border: none;
  box-shadow: 0 0.125rem 0.25rem rgba(0,0,0,0.075);
  border-radius: 0.5rem;
  overflow: hidden;
  width: 100%;
}

/* Make sure the card body uses full width */
.card-body {
  width: 100%;
  padding: 2rem; /* Increased padding */
}

/* Larger spacing for form elements */
.form-group {
  margin-bottom: 1.5rem;
}

.form-control {
  padding: 0.75rem; /* Larger padding in inputs */
  font-size: 1rem;
}

/* More spacious text areas */
textarea.form-control {
  padding: 1rem;
  line-height: 1.6;
}
</style>
