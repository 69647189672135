<template>
  <div class="saved-files mt-4">
    <h5 class="mb-3">
      Saved Files
    </h5>

    <div v-if="loading"
         class="text-center py-3"
    >
      <b-spinner small />
      <span class="ml-2">Loading files...</span>
    </div>

    <div v-else-if="files.length === 0"
         class="text-center text-muted py-3"
    >
      <p>No saved files yet</p>
    </div>

    <div v-else
         class="list-group"
    >
      <div v-for="file in files"
           :key="file.id"
           class="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
      >
        <div class="d-flex flex-column">
          <div class="d-flex align-items-center">
            <feather-icon
              icon="FileTextIcon"
              size="16"
              class="mr-2"
            />
            <span>{{ file.name }}</span>
          </div>
          <div class="d-flex align-items-center mt-1 text-muted small">
            <feather-icon
              icon="ClockIcon"
              size="12"
              class="mr-1"
            />
            <span>{{ FROM_NOW(file.created_at) }}</span>
          </div>
        </div>

        <div class="btn-group">
          <b-button v-b-tooltip.hover
                    size="sm"
                    variant="outline-primary"
                    class="btn-icon"
                    title="Open"
                    @click="openFile(file)"
          >
            <feather-icon
              icon="FolderIcon"
              size="16"
            />
          </b-button>
          <b-button v-b-tooltip.hover
                    size="sm"
                    variant="outline-secondary"
                    class="btn-icon"
                    title="Download"
                    @click="downloadFile(file)"
          >
            <feather-icon
              icon="DownloadIcon"
              size="16"
            />
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BButton, BSpinner } from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  name: 'SavedFilesList',
  components: {
    BButton,
    BSpinner,
  },
  data() {
    return {
      files: [],
      loading: false,
    }
  },
  created() {
    this.fetchFiles()
  },
  methods: {
    async fetchFiles() {
      this.loading = true
      try {
        const response = await useJwt.getPdfParsers()
        this.files = response.data.data
      } catch (error) {
        console.error('Error fetching files:', error)
      } finally {
        this.loading = false
      }
    },
    async handleDelete(id) {
      try {
        await useJwt.deletePdfParser(id)
        this.$emit('file-deleted', id)
        await this.fetchFiles()
      } catch (error) {
        console.error('Error deleting file:', error)
      }
    },
    async openFile(file) {
      try {
        // Get the file URL
        const fileUrl = file.file

        // Create a new XMLHttpRequest
        const xhr = new XMLHttpRequest()
        xhr.open('GET', fileUrl, true)
        xhr.responseType = 'text'

        // Handle the response
        xhr.onload = () => {
          if (xhr.status === 200) {
            const content = xhr.responseText
            this.$emit('file-opened', content)
          } else {
            throw new Error('Failed to load file')
          }
        }

        // Handle errors
        xhr.onerror = () => {
          throw new Error('Error fetching file')
        }

        // Send the request
        xhr.send()
      } catch (error) {
        console.error('Error opening file:', error)
      }
    },
    async downloadFile(file) {
      // Create a blob from the markdown content
      const blob = new Blob([file.file], { type: 'text/markdown' })

      // Create a temporary URL for the blob
      const url = window.URL.createObjectURL(blob)

      // Create a temporary link element
      const link = document.createElement('a')
      link.href = url
      link.download = file.name // Remove .md extension since file already has it

      // Append link to body, click it, and remove it
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)

      // Clean up by revoking the blob URL
      window.URL.revokeObjectURL(url)
    },
  },
}
</script>

<style scoped>
.saved-files {
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  padding: 1rem;
}

.list-group-item {
  transition: background-color 0.2s ease;
}

.list-group-item:hover {
  background-color: #f8f9fa;
}

.btn-group .btn {
  margin-left: 0.25rem;
}
</style>
